import React from 'react'
import { graphql } from 'gatsby'
import PersonalTitle from '../components/site-title'
import Footer from '../components/footer'
import NavigationInterior from '../components/navigation-interior'

const AboutPage = ( props ) => {
    const bioDescription = {__html: props.data.allContentfulAboutMe.edges[0].node.childContentfulAboutMeBioDescriptionTextNode.childMarkdownRemark.html || 'Content not found' };
    const imageSrc = props.data.allContentfulAboutMe.edges[0].node.aboutMeImage.fluid.src;
    return (
             <div>
              <div key="two" className="single-post generic-container">
                <PersonalTitle key="four"></PersonalTitle>
                  <div key ="three" className="single-post-inner">
                      <div className="eighty margin-auto">
                          <img src={imageSrc} alt="post-img"/>
                      </div>
                    <div key="five" className="bio-description-body" dangerouslySetInnerHTML={bioDescription}>
                    </div>
                  </div>
                <NavigationInterior key="six"></NavigationInterior>
              </div>
              <Footer key="seven"></Footer>
             </div>
    )
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query {
    allContentfulAboutMe { 
    edges {
      node {
        id
        childContentfulAboutMeBioDescriptionTextNode {
          childMarkdownRemark {
              html
          }
        }
        aboutMeImage {
          fluid(quality: 100) {
            src
          }
        }
      }
    }
  }
}
`